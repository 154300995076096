window.addEventListener("turbo:load", function(event) {
  
  $('ul.tabs li').click(function(){
    var tab_id = $(this).attr('data-tab');
    activateTab(tab_id);
  })

})

function activateTab(tab_id) {
  $('ul.tabs li').removeClass('current');
  $('.tab-content').removeClass('current');

  $('ul.tabs li[data-tab="' + tab_id + '"]').addClass('current');
  $("#"+tab_id).addClass('current');  
  location.hash= "#"+tab_id;    // extra anchor reference needed for Chrome and Edge
}

export {activateTab}