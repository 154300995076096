import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    // console.log("Hello, Autocomplete!", this.element)
    if (this.element.dataset.acTargetId) {
      $(this.element).autocomplete(getAutocompleteForIdOptions);
    } else {
      $(this.element).autocomplete(freeAutocompleteOptions);
    }
  }

}


/* Generic Abstract options Object for Autocomplete items requiring setting an :id value in ac-target-id field. Clone - deep copy - this object and set iets source: option. */
var getAutocompleteForIdOptions= {
  minLength: 0,
  source: function( request, response ) {
    $.getJSON( $(this.element).data('ac-url'), {
      autocomplete: 1,
      current_id: $(this.element).data('ac-current-id'),
      term: request.term
    }, response);
  },  
  select: function( event, ui ) {
    // A valid item has been selected from the menu
    // console.log("Autocomplete select: " + ui.item);
    var val= ui.item ? ui.item.id : '';
    var target = $(this).data('ac-target-id');
    $(this).siblings(target).attr('value', val);
    $(this).attr('value', ui.item.value);
    $(this).change();
  },
  change: function( event, ui) {
    // Field left (blurred) and value changed
    // console.log("Autocomplete change: " + ui.item);
    if (!ui.item) {
      // No valid item selected
      var target = $(this).data('ac-target-id');
      $(this).siblings(target).attr('value', null);
      $(this).attr('value', '');
    }
  },
  response: function( event, ui) {
    // Autocomplete menu responded to entry change
    // console.log("Autocomplete response: " + event.target.value);
    if (event.target.value==='') {
      // Former value removed, clear target ID
      var target = $(this).data('ac-target-id');
      $(this).siblings(target).attr('value', null);
      $(this).attr('value', '');      
    }
  }
};


/* List names */
var freeAutocompleteOptions=
  {source: function( request, response ) {
    $.getJSON( $(this.element).data('ac-url'), {
      autocomplete: 1,
      term: request.term
    }, response);
  },
  minLength: 0
};
