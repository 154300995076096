import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log("Hello, MultiFields!", this.element)
  }

  show_next(event) {
    const inputElm= event.target;
    // console.log("Leaving this field with value " + inputElm.value);

    // Determine what the next Element to show will be
    let nextElm= null;
    if (inputElm.dataset.multiFieldsNext=='next') {
      // Set the next SiblingElement
      nextElm= inputElm.nextElementSibling;
    } else if (inputElm.dataset.multiFieldsNext.startsWith('#')) {
      // Find the specified Element by ID
      nextElm= document.querySelector(inputElm.dataset.multiFieldsNext);
    } else  {
      // Find the specified Element by container class
      const contElm= inputElm.closest(inputElm.dataset.multiFieldsNext);
      // And use the next sibling container
      if (contElm) {
        nextElm= contElm.nextElementSibling;
        if (nextElm.type=='hidden') nextElm= nextElm.nextElementSibling;  // Skip any hidden field
      }
    }

    console.log(`Next elm: ${nextElm.id}`)

    if (nextElm) {
      if ( inputElm.value==undefined || inputElm.value.length==0) {
        // Geen waarde ingegeven, verberg alle volgende invoervelden
        if (nextElm.value && nextElm.value.length==0) nextElm.classList.add('hide');
      } else {
        // Waarde ingevuld, toon volgende veld
        if (nextElm.classList.contains('hide')) {
          nextElm.classList.remove('hide');

          // Bepaal nu welk veld de focus krijgt. 
          if (nextElm.autofocus) {
            // Het volgende item zelf omdat daar Autofocus is opgegeven
            nextElm.focus();
          } else {
            let childElm= null;
            if (childElm= nextElm.querySelector('[autofocus]')) {
              // Eén van de childElements met Autofocus van het volgende item
              childElm.focus();
            } else {
              // Dan toch maar het volgende item zelf
              nextElm.focus();
            }
          }
          
        }
      }
    }
  }

}
