document.addEventListener("turbo:load", () => {bind_form_helpers(document)})

/* Submit form when Enter given on a Select input element (search filter form) */
$(document).on('keypress', 'form select', function(event) {
  if (event.which==13) {
    $(event.currentTarget).closest('form').submit();
  }
})

/* Submit form directly on changing one of the input fields */
$(document).on('change', "form [data-auto-submit='true'], form[data-auto-submit='true']", function(event) {
  // Use this construction to support remote forms via UJS. 
  let form= $(event.currentTarget).closest('form')[0];
  return submit_remote_form(form, event);
})

/* View filters (nog niet in gebruik hier)
 * Submit form once one of the filter radio buttons has been pressed
 */
$(document).on('change', '.btn-group[data-bs-toggle="buttons"][data-submit="true"] label', function(event) {
  // Use this construction to support remote forms via UJS. 
  let form= $(event.currentTarget).closest('form')[0];
  return submit_remote_form(form, event);
})


function bind_form_helpers(html) {
  /*  Focus on the first editable field.
      Note: this must be set after binding of any Autocomplete field to prevent the data being lost
  */
  $("form input[type!='hidden'][type!='submit'][type!='reset']:enabled, form select:enabled, form textarea:enabled").not('.nofocus, .error, .date_picker').first().focus();

  /* Radiobuttons with nested labels. Make labels active when nested radiobutton is checked. */
  $(".form-group.radio_buttons input:checked").parent('label').addClass('active');
}


export function submit_remote_form(form, event) {
  if (form.dataset['remote']=='true') {
    // Works with non-remote forms on Firefox too
    Rails.handleRemote.call(form, event);
  } else {
    // Needed for Chrome/Safari for non-remote forms
    form.submit();
  }
  return false;  
}