// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

//---- jQuery(UI) ----
import './src/jquery'
import 'jquery-ui/dist/jquery-ui'

//---- JavintoAppPages
require("./packs/content/content_assets")
require("./packs/content/content_pages")

//---- Conditionals
import * as conditionals from "./packs/conditionals.js"
window.addEventListener("turbo:load", () => {conditionals.bind(document)});

//---- pidwijzer.nl ----
require("./packs/auto_submit");
require("./packs/bootstrap_activation");

require("./pid/tab");
require("./pid/pid_result");