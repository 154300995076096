import * as tab from "./tab.js"

// Required percentage for 'winner'
const percentage = 5;

// Maximum number of 'importance' checkbox checks allowed 
const maxImportantChecks = 5;

if (lang=='en') {
  // Graph tooltips
  var title1= 'Level of preference for Persistent Identifier solution';
  var title2= 'Overall preference';
  var label = 'Conformity (%)';  
  var maxImportantChecksWarning= "You can check no more than " + maxImportantChecks + " questions as highly important";

  // Texts for result summary
  var arkText = "Archival Resource Key (ARK) identifiers are persistent URLs designed to identify objects of any type: digital objects, physical objects, living beings and groups and intangible objects. The n2t.net resolver ensures global resolvability. There are no fees to assign or use ARKs, but some technical capability is required to implement ARKs. You can host ARKs on your own web server or move ARKs to other servers without losing their core identities. Visit the <a href='https://arks.org' target='_blank'>ARK Alliance</a> for more information about ARKs.";
  var urnnbnText = "URN:NBN is, in principle, a free service. URN:NBNs are not just concerned with the persistence of the identifier itself but also with the persistence of the identified material and the way in which it is resolved. Because of this, it is important that the digital objects, and their mandatory metadata, are housed in a Trusted Digital Repository. To attain this status, a repository should ideally be the recipient of the CoreTrustSeal (https://coretrustseal.org) or higher levels of certification. The objects linked to this type of persistent identifier are often publications, such as books, newspapers or magazines, but could also be datasets. The idea is that a Persistent Identifier points to the correct landing page for an object, and in doing so prevents external information loss. You can find more information on the website of the KB, National Library' <a href='https://www.kb.nl/en/about-us/services/nbn-agency-nl' target='_blank'>NBN registration agency pages</a>";
  var handleText = "The beauty of Handles is that they are relatively quick, cheap and easy to assign to a large number of digital objects. There is also an active user community surrounding this type of Persistent Identifier, so you should be able to find help with any questions you might have. A disadvantage for this type of identifier is that there is no standardised policy for the metadata required, or the persistence of the objects themselves. Choosing Handles gives you a lot of freedom, but it does mean that you will have to do some self regulation in terms of metadata standardisation. You can find out <a href='https://www.surf.nl/data-persistent-identifier' target='_blank'> more information</a> about this type of Persistent Identifier through SURF, which offers this service in co-operation with the European Persistent Identifier Consortium (EPIC).";
  var datacitedoiText = "DataCite DOIs (Digital Object Identifiers) are best suited to identifying objects and texts for citation purposes. This was the main purpose of DataCite DOIs, and is why they are most often used with academic and scientific publications. DOIs generally link to published datasets and less so to individual objects within datasets. The use of DataCite DOIs is slightly more regulated, as you are required to supply standard metadata elements in addition to paying a yearly cost of 750 euro. However, this cost covers the creation of unlimited identifiers, and support from DataCite. More information about DataCite DOIs in the Netherlands is available via <a href='https://www.tudelft.nl/en/library/support/datacite-netherlands' target='_blank'>DataCite Netherlands at the TU Delft Library </a>.";
  var oneWinnerText = "Your results from the Persistent Identifier Guide have revealed a &quot;winner&quot;. With a difference of more than " + percentage + " percent with number 2, your results show a clear preference for ";
  var twoWinnersText = "Your results from the Persistent Identifier Guide have revealed two &quot;winners&quot;. As the difference between the numbers 1 and 2 is smaller than " + percentage + " percent, and slightly greater between numbers 2 and 3, we will outline the advantages and disadvantages from both systems to help you make your choice.";
  var noWinnersText = "Your results from the Persistent Identifier Guide have revealed no clear preference for any one type. None of the sections show a difference of more than " + percentage + " percent. So, in order to help you with your choice, here are the advantages and disadvantages of each system."


} else {
  // Grafiek tooltips
  var title1= 'Mate van overeenkomst met Persistent Identifier oplossing';
  var title2= 'Mate van overeenkomst in totaal';
  var label = 'Overeenkomst (%)';
  var maxImportantChecksWarning= "U kunt maximaal " + maxImportantChecks + " stellingen als extra belangrijk aanvinken";

  // Teksten voor de samenvatting van het resultaat
  var arkText = "Archival Resource Key (ARK) identifiers zijn persistente URL's, die ontwikkeld zijn om objecten van ieder type te identificeren: digitale objecten, fysieke objecten, levende wezens en groepen en abstracte objecten. De n2t.net resolver verzekert wereldwijde resolveerbaarheid. Er zijn geen kosten verbonden aan ARK's, maar er is enige technische capaciteit nodig om ze te implementeren. Je kunt ARK's op je eigen webserver hosten, or ARK's naar andere servers verplaatsen, zonder de identifiers te verliezen. Bezoek de <a href='https://arks.org' target='_blank'>ARK Alliance</a> voor meer informatie over ARK's.";
  var urnnbnText = "URN:NBN is in principe een gratis dienst. URN:NBN richt zich niet alleen op de persistentie van de identifier, maar ook van de persistentie van het geïdentificeerde materiaal en het resolutiemechanisme. Bij URN:NBN is het daarom belangrijk dat objecten, voorzien van bepaalde verplichte metadata, in een Trusted Digital Repository  staan. Die repository heeft minstens het Data Seal of Approval (www.datasealofapproval.org) verkregen. De objecten waaraan de PID\u2019s gekoppeld zijn, zijn meestal geschreven publicaties, zoals boeken, kranten en tijdschriften, maar het kunnen ook datasets zijn. Iedere PID verwijst in principe naar een landing page voor het object. Bij de KB, nationale bibliotheek vind je <a href='https://www.kb.nl/over-ons/diensten/nbn-registration-agency' target='_blank'>hier</a> meer informatie over URN:NBN in Nederland."
  var handleText = "Het mooie van Handle is dat het redelijk snel, goedkoop en eenvoudig is toe te passen op grote hoeveelheden objecten. Er is een grote gebruikerscommunity rond Handle. Nadeel van die vrijheid is dat er geen beleid is voor metadatering en de persistentie van de objecten. Ook bij het gebruik van Handle heb je veel vrijheid, maar zul je veel zelf moeten (laten) doen. Bij SURF vind je <a href='https://www.surf.nl/data-persistent-identifier' target='_blank'>hier</a> meer informatie over de PID-diensten die men samen met het Europese Persistent Identifier Consortium (EPIC) aanbiedt.";
  var datacitedoiText = "DataCite DOI is zeer geschikt om objecten identificeerbaar en wetenschappelijk citeerbaar te maken. Daar is DataCite DOI voor bedoeld, en daar wordt het om (h)erkend in de wetenschappelijke wereld. DataCite DOI&apos;s koppel je vooral aan (gepubliceerde) datasets en minder snel aan alle individuele objecten waaruit die datasets mogelijk bestaan. DataCite DOI vereist bepaalde standaard metadata en vraagt een jaarbedrag van 750 Euro. Daar staat tegenover dat je bij DataCite DOI kunt rekenen op behoorlijke ondersteuning. Bij de TUDelft vind je <a href='https://www.tudelft.nl/library/support/datacite-netherlands' target='_blank'>hier</a> informatie over DataCite DOI in Nederland.";
  var oneWinnerText = "Je invulling van de PID-wijzer levert met meer dan " + percentage + " procent verschil een duidelijke voorkeur op voor ";
  var twoWinnersText = "Je invulling van de PID-wijzer levert twee &quot;winnaars&quot; op, omdat het procentuele verschil tussen de nummers 1 en 2 kleiner is dan " + percentage + ", en tussen de nummers 2 en 3 juist groter. Hieronder volgen in het kort de voor- en nadelen van beide systemen, om je te helpen bij het maken van een keuze tussen ";
  var noWinnersText = "Je invulling van de PID-wijzer levert geen duidelijke voorkeur op. Nergens treedt een verschil op van meer dan " + percentage + " procent. Hieronder volgen in het kort de voor- en nadelen van de systemen, om je te helpen bij het maken van een keuze.";
}

if (typeof(google)!='undefined') {
  window.addEventListener("turbo:load", function(event) {
    var total_answers= $('.answer').length;

    $('#progress').attr('max', total_answers);

    $('#next_btn_intro, #next_btn_1, #next_btn_2, #next_btn_3, #next_btn_4, #next_btn_5').click(function(event) {
      var new_tab= 'tab-' + $(this).data('targetTab');
      var theme_answers= $(this).closest('.tab-content').find('.rij.answer').length;
      if ($(':radio:visible:checked').length==theme_answers) tab.activateTab(new_tab);

      if(new_tab=='tab-r') {
        // Deze is nodig omdat het genereren van de grafiek in hidden content niet goed werkt.
        let handleTotal= parseFloat(document.getElementById('pid_result_handle_total').value);
        let urnnbnTotal= parseFloat(document.getElementById('pid_result_urnnbn_total').value);
        let datacitedoiTotal= parseFloat(document.getElementById('pid_result_datacitedoi_total').value);
        let arkTotal= parseFloat(document.getElementById('pid_result_ark_total').value);

        draw_chart('chartContainerTotal', [handleTotal, urnnbnTotal, datacitedoiTotal, arkTotal], title2);        
      }
    });

    $('.answer.distance :radio').change(function(event) {
      var elm= event.currentTarget;
      getResultsDistance(elm, $(elm).data('nr') );
    });

    $('div.answer :radio').click(function(event) {
      // Onclick answer go to this answers bookmark to scroll down and see next question
      var nr= $(this).data('nr');
      window.location.hash="#stelling" + nr;
    });

    $('div.answer :radio').click(function(event) {
      var theme= $(this).data('theme');
      if (theme==1 && $(':radio:visible:checked').length==$('#tab-1').children('.answer').length) {
        $('#next_btn_1').removeClass('inactive').attr('title', null);
      }
      if (theme==2 && $(':radio:visible:checked').length==$('#tab-2').children('.answer').length) {
        $('#next_btn_2').removeClass('inactive').attr('title', null);
      }
      if (theme==3 && $(':radio:visible:checked').length==$('#tab-3').children('.answer').length) {
        $('#next_btn_3').removeClass('inactive').attr('title', null);
      }
      if (theme==4 && $(':radio:visible:checked').length==$('#tab-4').children('.answer').length) {
        $('#next_btn_4').removeClass('inactive').attr('title', null);
      }
      if (theme==5 && $(':radio:visible:checked').length==$('#tab-5').children('.answer').length) {
        $('#next_btn_5').removeClass('inactive').attr('title', null);
      }

      $('#progress').attr('value', $('div.answer :radio:checked').length);
    });

    // Other questions with 'other' as option: show 'namely' field only if 'other' selected

    /* Instelling */
    $('[name="pid_result[instelling_anders]"]').addClass('hide');
    $('[name="pid_result[instelling]"]').not('#pid_result_instelling_anders').click(function(event) {
      $('[name="pid_result[instelling_anders]"]').val('').addClass('hide');

    });
    $('#pid_result_instelling_anders').click(function(event) {
      $('[name="pid_result[instelling_anders]"]').removeClass('hide');
    });

    /* Functie */
    $('[name="pid_result[functie_anders]"]').addClass('hide');
    $('[name="pid_result[functie]"]').not('#pid_result_functie_anders').click(function(event) {
      $('[name="pid_result[functie_anders]"]').val('').addClass('hide');

    });
    $('#pid_result_functie_anders').click(function(event) {
      $('[name="pid_result[functie_anders]"]').removeClass('hide');
    });

    /* Software */
    $('[name="pid_result[software_anders]"]').addClass('hide');
    $('[name="pid_result[software]"]').not('#pid_result_software_anders').click(function(event) {
      $('[name="pid_result[software_anders]"]').val('').addClass('hide');

    });
    $('#pid_result_software_anders').click(function(event) {
      $('[name="pid_result[software_anders]"]').removeClass('hide');
    });


  });

  window.addEventListener("click", function(event) {
    if (event.target.matches("input[type='checkbox'].important")) {
      if (document.querySelectorAll("input[type='checkbox'].important:checked").length> maxImportantChecks) {
        alert(maxImportantChecksWarning);
        event.target.checked = false;
      }
    } else if (event.target.dataset.onLabel) {
      if (event.target.classList.contains('collapsed')) {
        event.target.innerHTML= event.target.dataset.offLabel;
        window.scrollTo(0,0);   // Scroll To Top
      } else {
        event.target.innerHTML= event.target.dataset.onLabel;
      }
    }
  })

  var bar_width= 0;

  // Load the Visualization API and the corechart package.
  google.charts.load('current', {'packages':['corechart'], 'language': 'nl'});

  // Set a callback to run when the Google Visualization API is loaded.
  google.charts.setOnLoadCallback(initAnswers);

  var barchart_options = {
    title: title1,
    chartArea: {width: '55%'},
    backgroundColor: '#f5f4f2',
    height: 110,
    width: 519,
    hAxis: {
      minValue: 0,
      maxValue: 100,
      textPosition: 'left'
    },
    legend: { position: 'none' }
  };

  /* Initialize any given answers in advance on validation situation */
  function initAnswers() {
    bar_width= $('.tabcontent:visible').width();
    barchart_options.width= bar_width;

    $('.answer.distance :radio:checked').each(function(index) {
      if (this.value >'') getResultsDistance(this, $(this).data('nr') );
    });
  }

  // Scores (answers) assigend to each question by PID experts
  var handleScores =     [1,1,3,5,5,2,3,4,5,5,5,5,5,2,5,2,2,4,5,3,1,1,5,2,4];
  var urnnbnScores =     [5,5,3,3,1,5,5,2,1,1,1,1,1,5,3,4,5,1,1,3,3,1,3,5,1];
  var datacitedoiScores= [5,5,5,3,2,3,2,4,1,1,4,1,4,5,1,4,1,3,2,5,2,4,5,5,3];
  var arkScores =        [1,4,4,3,5,3,3,3,3,3,3,4,5,2,4,1,5,5,5,3,3,1,5,4,5];
  // Results arrays must be of same lenth as Scores array to prevent index errors
  var handleResults =    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]; // Array must be of same length as Scores-array
  var urnnbnResults =    [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]; // Array must be of same length as Scores-array
  var datacitedoiResults=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]; // Array must be of same length as Scores-array
  var arkResults =       [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]; // Array must be of same length as Scores-array


  function getResultsDistance(selectedValue, questionNumber) {
    // Build id name of chart and explanaton based on questionNumber
    var chartContainer = "chartContainer" + questionNumber.toString();
    var explanationNumber = "uitleg" + questionNumber.toString();
    // QuestionNumber is one-based, while array indexes are zero-based, so subtract 1 from questionNumber
    questionNumber -= 1;
    // calculation of score is based on MAXSCORE - ABS(DISTANCE BETWEEN VALUE OF EXPERTS AND ANSWER OF USER)
    var handleResult      = (5 - (Math.abs(selectedValue.value - handleScores[questionNumber])))/5*100;
    var urnnbnResult      = (5 - (Math.abs(selectedValue.value - urnnbnScores[questionNumber])))/5*100;
    var datacitedoiResult = (5 - (Math.abs(selectedValue.value - datacitedoiScores[questionNumber])))/5*100;
    var arkResult         = (5 - (Math.abs(selectedValue.value - arkScores[questionNumber])))/5*100;

    // Draw Chart
    draw_chart(chartContainer, [handleResult, urnnbnResult, datacitedoiResult, arkResult]);

    handleResults[questionNumber] = handleResult;
    urnnbnResults[questionNumber] = urnnbnResult;
    datacitedoiResults[questionNumber] = datacitedoiResult;
    arkResults[questionNumber] = arkResult;

    document.getElementById(explanationNumber).style.display = "inline-block";
    showTotalResults();
  }

  function showTotalResults() { 
    var handleTotal = 0;
    var urnnbnTotal = 0;
    var datacitedoiTotal = 0;
    var arkTotal = 0;
    // Make total results only appear if all questions have been answered.
    // I.e.: no score is 0 (whether it's Handle, URN:NBN or DataCite DOI)
    for (var i = 0; i < datacitedoiResults.length; i++){
      if (datacitedoiResults[i] == 0){
        return;
      }
    }

    // Sum up all result scores into a grand total
    var importants = document.querySelectorAll("input[type='checkbox'].important");
    for (var i = 0; i < datacitedoiScores.length; i++){
      var multiplier = 1;
      if (importants[i].checked){
        multiplier = 2;
      }
      handleTotal += handleResults[i] * multiplier;
      urnnbnTotal += urnnbnResults[i] * multiplier;
      datacitedoiTotal += datacitedoiResults[i] * multiplier;
      arkTotal += arkResults[i] * multiplier;
    }
    
    // Calculate percentages from the actual values
    handleTotal = handleTotal/(handleScores.length);
    urnnbnTotal = urnnbnTotal/(urnnbnScores.length);
    datacitedoiTotal = datacitedoiTotal/(datacitedoiScores.length); 
    arkTotal = arkTotal/(arkScores.length);

    // Hocus pocus: 
    // Sort and compare the totals of the PID systems
    // Then decide one winner, two winners or no winners
    var totals = {"Handle": handleTotal, "URN:NBN": urnnbnTotal, "DataCite DOI": datacitedoiTotal, "ARK": arkTotal};
    var sorted = [];
    for (var t in totals){
      sorted.push([t, totals[t]]);
    }
    sorted.sort(function(a,b){return b[1] - a[1]});
    var diff12 = 100 - sorted[1][1] / sorted[0][1] * 100;
    var diff23 = 100 - sorted[2][1] / sorted[1][1] * 100;
    if (diff12 > percentage){
      showOneWinner(sorted[0][0]);
    }
    else if (diff23 > percentage){
      showTwoWinners(sorted[0][0], sorted[1][0]);
    }
    else{
      showNoWinner();
    }

    if (handleTotal) {
      $('#pid_result_handle_total').val(handleTotal);
      $('#pid_result_urnnbn_total').val(urnnbnTotal);
      $('#pid_result_datacitedoi_total').val(datacitedoiTotal);
      $('#pid_result_ark_total').val(arkTotal);
      $('#next_btn_5').removeClass('hide');

      // Deze is nodig als iemand de link naar zijn pid-wijzer achteraf opvraagt
      draw_chart('chartContainerTotal', [handleTotal, urnnbnTotal, datacitedoiTotal, arkTotal], title2);
    }
  }

  function showNoWinner(){
    // There is no clear 'winner'
    document.getElementById("resultaat").innerHTML = "<p>" + noWinnersText + "</p><p>" + handleText + "</p><p>" + urnnbnText + "</p><p>" + datacitedoiText + "</p><p>" + arkText + "</p>";
  }

  function showOneWinner(name){
    // There is one clear 'winner'
    // Start with Handle, change text if name is other
    var text = handleText;
    if (name == "URN:NBN"){
      text = urnnbnText;
    }
    else if (name == "DataCite DOI"){
      text = datacitedoiText;
    }
    else if (name == "ARK"){
      text = arkText;
    }
    document.getElementById("resultaat").innerHTML = "<p>" + oneWinnerText + name + ".</p><p>" + text + "</p>";
  }

  function showTwoWinners(name1, name2){
    // There are two 'winners'
    var text = handleText + "</p><p>" + urnnbnText;
    if ((name1 == "Handle" && name2 == "DataCite DOI") || (name1 == "DataCite DOI" && name2 == "Handle")){
      text = handleText + "</p><p>" + datacitedoiText;
    }
    else if ((name1 == "URN:NBN" && name2 == "DataCite DOI") || (name1 == "DataCite DOI" && name2 == "URN:NBN")){
      text = urnnbnText + "</p><p>" + datacitedoiText;
    }
    
    else if ((name1 == "URN:NBN" && name2 == "ARK") || (name1 == "ARK" && name2 == "URN:NBN")){
      text = urnnbnText + "</p><p>" + arkText;
    }
    else if ((name1 == "Handle" && name2 == "ARK") || (name1 == "ARK" && name2 == "Handle")){
      text = handleText + "</p><p>" + arkText;
    }
    else if ((name1 == "ARK" && name2 == "DataCite DOI") || (name1 == "DataCite DOI" && name2 == "ARK")){
      text = arkText + "</p><p>" + datacitedoiText;
    }
    document.getElementById("resultaat").innerHTML = "<p>" + twoWinnersText + name1 + " en " + name2 + ".</p><p>" + text + "</p>";
  }


  function draw_chart(chartId, values, title) {
    // Draw chart  
    var data = google.visualization.arrayToDataTable([
      ['Label', label, { role: 'style' }],
      ['Handle', values[0], '#673d67'],
      ['URN:NBN', values[1], '#377a6c'],
      ['DataCite DOI', values[2], '#dfb519'],
      ['ARK', values[3], '#4872b2']
    ]);

    if (title) barchart_options.title= title;

    document.getElementById(chartId).style.display = "inline-block";

    var chart = new google.visualization.BarChart(document.getElementById( chartId));
    chart.draw(data, barchart_options);  
  }
}